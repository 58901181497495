import React from "react";
import { videoTagString, VideoTag } from "react-video-tag";

videoTagString({
  src: "https://klemobucket.s3.ap-northeast-1.amazonaws.com/Untitled+video+-+Made+with+Clipchamp.mp4",
});

const Video = () => {
  return (
    <>
      <VideoTag
        autoPlay={`${true}`}
        muted={`${true}`}
        playsInline={`${true}`}
        loop={`${true}`}
        src={`${"https://klemobucket.s3.ap-northeast-1.amazonaws.com/Untitled+video+-+Made+with+Clipchamp.mp4"}`}
      />
    </>
  );
};

export default Video;
