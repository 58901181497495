import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";
import { GoogleOAuthProvider } from '@react-oauth/google';
const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <GoogleOAuthProvider clientId="417970531436-2vrib1mi4pcmm2rmmierhur548b494uf.apps.googleusercontent.com">
        <Helmet>
          <title> Gamefolio || Play Your Architecture Design</title>
          <meta property="og:site_name" content="gamefolio" />
          <meta
            property="og:url"
            content="https://themeforest.net/user/ib-themes"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Gamefolio:  Play Your Architecture Design"
          />
          <meta
            name="keywords"
            content="architecture, portfolio, architecturalportfolio, game, blender, 3dmodel, 3dgame, reactgame, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company"
          />
          <meta
            name="description"
            content=" Redefining Architectural Portfolios through Innovative Gamification.."
          />
          <meta name="description" content="  Redefining Architectural Portfolios through Innovative Gamification" />
        </Helmet>
        {/* End Seo Helmt */}

        <ScrollToTop />
        {/* End Scroll top */}

        <AllRoutes />
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
