import React from "react";

const FeatureContent = [
  {
    icon: "132",
    title: "Create ID",
    subTitle: `Sign up to unlock the door to a world where your architectural visions become interactive wonders..`,
    bgColor: "#FFEBDB",
    delayAnimation: "",
  },
  {
    icon: "133",
    title: "Upload Your 3D Model:",
    subTitle: ` Transform your designs with ease – upload your 3D models and witness them evolve into playable showcases..`,
    bgColor: "#E0F8F8",
    delayAnimation: "200",
  },
  {
    icon: "134",
    title: "Play and Share Instantly:",
    subTitle: `Receive a unique link to your dynamic creation. Share it effortlessly for a remarkable portfolio that speaks volumes with just a click..`,
    bgColor: "#F7EDFF",
    delayAnimation: "400",
  },
];

const FancyFeatureTewentyThree = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <div className="block-style-twentyTwo">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>
            <a className="arrow-icon" href="#">
              <img
                className="arrow-icon"
                src="images/icon/135.svg"
                alt="icon"
              />
            </a>
          </div>
          {/* /.block-style-twentyTwo */}
        </div>
      ))}
    </>
  );
};

export default FancyFeatureTewentyThree;
