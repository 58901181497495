import React, { useState } from "react";
import { Helmet } from "react-helmet";
import DocEditorTabs from "../../components/block-slider/DocEditorTabs";
import FancyFeatureNinteen from "../../components/features/FancyFeatureNinteen";
import HeaderLandingEditor from "../../components/header/landing/HeaderLandingEditor";
import HeroBannerEight from "../../components/hero-banner/HeroBannerEight";
import TestimonialSeven from "../../components/testimonial/TestimonialSeven";
import FooterSeven from "../../components/footer/FooterSeven";
import CopyRightThree from "../../components/footer/CopyRightThree";
import FacnyVideoTwo from "../../components/video/FacnyVideoTwo";
import FacnyVideoThree from "../../components/video/FacnyVideoThree";
import VideoGallery from "../../components/video/VideoGallery";
import FancyFeatureTwelve from "../../components/features/FancyFeatureTwelve";
import FancyFeatureTewentyThree from "../../components/features/FancyFeatureTewentyThree";
import PricingSeven from "../../components/pricing/PricingSeven";
import Video from "../../elements/Video";
import Video1 from "../../elements/Video1";

const VideoEditorLanding = () => {
  // For header select menu
  const [click1, setClick1] = useState(false);
  const handleClick1 = () => setClick1(!click1);
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          Gamefolio || Play Your Architecture Design
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingEditor />
      {/* End Header Landing Editor */}

      {/* 	=============================================
				Theme Hero Banner
			==============================================  */}
      <div id="home">
        <HeroBannerEight />
      </div>

      {/* 		=============================================
				Fancy Feature Eighteen
			==============================================  */}

      <div
        className="fancy-feature-twentyTwo mpt-200 pt-130 md-mt-80 sm-pt-100"
        id="feature"
      >
        <div className="container">
          <div className="title-style-ten mb-40 md-mb-20">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h2>What are we doing?</h2>
              </div>
              <div className="col-lg-5">
                <p className="md-pt-20">
                  Redefining Architectural Portfolios through Innovative Gamification.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <FancyFeatureTewentyThree />
          </div>
          <div className="video-wrapper">
            <Video1 />
          </div>
        </div>
      </div>
      {/* <div className="fancy-feature-eighteen mt-130 md-mt-80" id="feature">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 m-auto">
              <div
                className="title-style-eight text-center"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h2>
                  Build with <br /> 800+ Stunning Effects
                </h2>
                <p>
                  Rotate, merge, trim, split, crop and flip videos with a modern
                  & simple ui
                </p>
              </div>
            </div>
          </div>

 
        </div>
      
        <DocEditorTabs />

        <img src="images/shape/175.svg" alt="" className="shapes shape-right" />
        <img src="images/shape/176.svg" alt="" className="shapes shape-left" />
      </div> */}
      {/* /.fancy-feature-eighteen */}

      {/* 	=============================================
				Fancy Feature Nineteen
			==============================================  */}
      <div className="fancy-feature-nineteen pt-180 md-pt-100" id="effect">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-9 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-eight text-center mb-80 md-mb-40">
                <h2>Playful Interiors and Views!</h2>
                <p>
                  Elevate Your Portfolio with Playable Architectural Designs for Unforgettable Impressions!
                </p>
              </div>
            </div>
          </div>
          {/* End .row */}
          <FancyFeatureNinteen />
        </div>
      </div>
      {/* /.fancy-feature-nineteen */}

      {/* 	=============================================
				Fancy Feature Twenty
			==============================================  */}
      <div className="fancy-feature-twenty mt-225 md-mt-150">
        <div className="container">
          <div className="block-style-twenty">
            <FacnyVideoTwo />
          </div>
          {/* /.block-style-twenty */}

          <div className="block-style-twenty mt-170 md-mt-120">
            <FacnyVideoThree />
          </div>
          {/* /.block-style-twenty */}
        </div>
      </div>
      {/* /.fancy-feature-twenty */}

      {/* 	=====================================================
				Fancy Feature Twenty One
			===================================================== */}
      {/* <div className="fancy-feature-twentyOne mt-200 md-mt-100" id="template">
        <div className="container">
          <div
            className="title-style-eight text-center mb-80 md-mb-30"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h2>Ready Stunning Gamefolio's</h2>
            <p>Quick and easy to build. No experience required.</p>
          </div>
        </div>

        <div className="lg-container">
          <div className="container">
            <VideoGallery />

            <div
              className="text-center mt-70"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <a href="https://app.gamefolio.in/" className="theme-btn-nine">
                Start Building
              </a>
            </div>
          </div>
        </div>
  
      </div> */}
      {/* /.fancy-feature-twentyOne */}

      {/* =====================================================
				Client Feedback Slider Six
			===================================================== */}
      <div
        className="client-feedback-slider-six pt-170 md-pt-120"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-7 col-lg-9 m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="title-style-seven text-center">
                  <h6>FEEDBACK</h6>
                  <h2>Trusted by 1000+ of students.</h2>
                </div>
              </div>
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
          <div className="clientSliderSix">
            <TestimonialSeven />
          </div>
        </div>
        {/* /.inner-container */}
      </div>
      {/* /.client-feedback-slider-six */}
      <div className="pricing-section-seven pt-200 md-pt-130" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
              <div className="title-style-eleven text-center mb-100 md-mb-70">
                <h2>No hidden charge, Choose your plan.</h2>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-seven">
            <div className="row align-items-center justify-content-center">
              <PricingSeven />
            </div>
          </div>
          {/* /.pricing-table-area-seven */}
        </div>
      </div>
      {/* 	=====================================================
				Fancy Short Banner Nine
			===================================================== */}
      <div className="fancy-short-banner-nine mt-170 md-mt-80">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-seven text-center">
                <h2>1k+ Customers have our gamefolio.Try it now!</h2>
                <p>Try it risk free — we don’t charge cancellation fees.</p>
              </div>
              {/*  /.title-style-six */}
            </div>
          </div>
          {/* End .row */}
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <div
              className={
                click1 ? "dropdown download-btn show" : "dropdown download-btn"
              }
            >
              <a href="https://app.gamefolio.in/">
                <button
                  className="dropdown-toggle"
                  onClick={handleClick1}
                  type="button"
                >
                  Build gamefolio
                </button>
              </a>
              {/* <div className={click1 ? "dropdown-menu  show" : "dropdown-menu"}>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={handleClick1}
                >
                  <img src="images/icon/103.svg" alt="icon" />
                  <span>IOS & Android</span>
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={handleClick1}
                >
                  <img src="images/icon/104.svg" alt="icon" />
                  <span>Mac & Windows</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        {/*  /.container */}
        <img
          src="images/shape/177.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/178.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/*  /.fancy-short-banner-nen */}

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          {/* <div className="container">
            <FooterSeven />
          </div> */}

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default VideoEditorLanding;
