import React from "react";

const featureContent = [
  {
    delayAnimation: "0",
    colorOfBorder: "#FFCE22",
    icon: "112",
    title: "Portfolio Gamification",
    description: `Elevate your portfolio by gamifying your design experiences..`,
  },
  {
    delayAnimation: "150",
    colorOfBorder: "#8F6BF6",
    icon: "113",
    title: "360View Experience",
    description: ` Provide a comprehensive and interactive view of designs.`,
  },
  {
    delayAnimation: "0",
    colorOfBorder: "#FF5C5C",
    icon: "114",
    title: "Interactive Showcase",
    description: ` Engage recruiters with a dynamic, immersive showcase.`,
  },
  {
    delayAnimation: "150",
    colorOfBorder: "#63EAA9",
    icon: "115",
    title: "Client Presentation",
    description: `Impress clients with a visually captivating presentations.`,
  },
  {
    delayAnimation: "",
    colorOfBorder: "#5BE2FF",
    icon: "116",
    title: "Multiplayer Collab",
    description: `Collaborate seamlessly with fellow students or professionals..`,
  },
  {
    delayAnimation: "150",
    colorOfBorder: "#FF56EE",
    icon: "117",
    title: "Audio VisualComm:",
    description: `Communicate effectively through integrated audio and video..`,
  },
];

const FancyFeatureNinteen = () => {
  return (
    <div className="row">
      {featureContent.map((val, i) => (
        <div
          className="col-lg-6"
          data-aos="fade-up"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <div
            className="block-style-nineteen"
            style={{ borderLeftColor: val.colorOfBorder }}
          >
            <div className="d-flex align-items-start">
              <img
                src={`images/icon/${val.icon}.svg`}
                alt="icon"
                className="icon"
              />
              <div className="text">
                <h4>{val.title}</h4>
                <p>{val.description}</p>
              </div>
            </div>
          </div>
          {/* /.block-style-nineteen */}
        </div>
      ))}

      {/* End .col */}
    </div>
  );
};

export default FancyFeatureNinteen;
