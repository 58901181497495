import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialSeven() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      img: "img_79",
      desc: `Gamefolio has been a game-changer for me! I've successfully closed 1 deal from the accelerator and am currently in the process of closing 2 more. The interactive portfolios created with Gamefolio have truly set me apart.`,
      reviewerName: "Elena Martinez",
      country: "Spain",
      bgColor: "#FF47E2",
    },
    {
      img: "img_78",
      desc: `Gamefolio has transformed how I present my designs. The immersive experiences created with Gamefolio are leaving a lasting impression on clients.`,
      reviewerName: "Jonathan Collins",
      country: "Canada",
      bgColor: "#00E5F3",
    },
    {
      img: "img_79",
      desc: `Gamefolio revolutionized my architectural portfolio presentation. The interactive experiences helped me stand out professionally, and surprisingly, they positively influenced my CGPA. A game-changer in both design showcase and academic performance!`,
      reviewerName: "Sophie Turner",
      country: "UK",
      bgColor: "#FFCF24",
    },
    // {
    //   img: "img_80",
    //   desc: `Gamefolio has played a pivotal role in closing 1 deal from the Accelerator, and I'm on track to close 2 more. The dynamic portfolios created with Gamefolio have significantly elevated my professional profile. More exciting opportunities on the horizon!`,
    //   reviewerName: "Alex Rodriguez",
    //   country: "USA",
    //   bgColor: "#8F6BF6",
    // }
    
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="feedback-wrapper">
            <span className="ribbon" style={{ background: val.bgColor }}></span>
            <p>{val.desc}</p>
            <div className="d-flex align-items-center">
              <img
                src={`images/media/${val.img}.png`}
                alt="icon"
                className="avatar"
              />
              <h6 className="name">
                {val.reviewerName},<span>{val.country}</span>
              </h6>
            </div>
          </div>
          {/* End .feedback-wrapper */}
        </div>
      ))}
    </Slider>
  );
}
